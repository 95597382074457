import m from 'mithril/mithril'
import Lenis from '@studio-freight/lenis'
let api_root_url = 'https://zazitmestojinak.cz/'
let api_program_url = 'wp-json/2046/v1/locations'
let api_menu_url = 'wp-json/2046/v1/menu'
let api_detail_url = 'wp-json/2046/v1/location';
let location_id = null; // 9365; // this will be defined by actual location id from CMS/url

var Loading =  {
    view: function(vnode){
        Loading.state ? document.body.classList.add('loading') : document.body.classList.remove('loading')
        return Loading.state && m('.loading', {}, [m('span')])
    }
}
// function findRootObject(obj, id, parent = null) {
//   if (obj.id === id) {
//     obj.active = true;
//     return parent;
//   }

//   for (const item of obj.items) {
//     const rootObject = findRootObject(item, id, obj);
//     if (rootObject) {
//         rootObject.active = true
//         return rootObject;
//     }
//   }

//   return null;
// }


function markActive(obj, targetId) {
  let targetFound = false;

  function findAndMark(item) {
    if (targetFound) return; // Stop if the target is already found
    if (item.id === targetId) {
      item.active = true;
      targetFound = true;
      return;
    }
    if (item.items && item.items.length > 0) {
      for (const child of item.items) {
        findAndMark(child);
      }
      if (targetFound) item.active = true; // Mark parent as active
    }
  }

  findAndMark(obj);
}


// function benchmarkFunction(func) {
//   const start = performance.now();
//   func();
//   const end = performance.now();
//   const executionTime = end - start;
//   console.log(`Execution time: ${executionTime} milliseconds`);
// }

// function test(){
//         // run function to test
// }

var Data = {
    program: {
        items: [],
        error: null,
        fetch: function() {
            Loading.state = true;
            m.request({
                method: "GET",
                url: api_root_url +  api_program_url
            })
            .then(function(data) {
                Data.program.items = data
                Loading.state = false;

                if(location_id != null){
                    markActive(Data.program, location_id);
                    
                }
                
            })
            .catch(function(e) {
                Data.program.error = e.message
                Loading.state = false;
            })
        },
        place: {
            error: null,
            item : null,
            fetch: function(id) {
                Loading.state = true;
                m.request({
                    method: "GET",
                    url: api_root_url + api_detail_url + '/' + id
                })
                .then(function(data) {
                    Data.program.place.item = data
                    Loading.state = false;
                })
                .catch(function(e) {
                    Data.program.error = e.message
                    Loading.state = false;
                })
            },
        }
    },
    menu:{
        active: false,
        items : [],
        error: null,
        fetch: function() {
            Loading.state = true;
            m.request({
                method: "GET",
                url: api_root_url +  api_menu_url
            })
            .then(function(data) {
                Data.menu.items = data
                Loading.state = false;
            })
            .catch(function(e) {
                Data.program.error = e.message
                Loading.state = false;
            })
        }
    }
};
const lenis = new Lenis()

// lenis.on('scroll', (e) => {
//   console.log(e)
// })

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
export { lenis }

function removeClass(elementName, className){
  var items = Array.from(document.getElementsByClassName(elementName));

  items.forEach(item => {
    item.classList.remove(className);
  });
}
function swapClass(element_class, cls){
  const element = document.getElementsByClassName(element_class);
  if(element[0].classList.contains(cls)){
    removeClass(element_class, cls)
  }else{
    element[0].classList.add(cls)
  }
}
function swapMenuTree(menu){
    swapClass('body', 'menu_exposed')
}
function closeMenuTree(){
    document.body.classList.remove('menu_exposed')
}
function reactivateTree(tree, item){
    tree.map(function(i, index) {
        i.active = i.id == item.id
    })
}
function getTreeData(items){
    let active_root = items.length > 0 ? items.filter((object) => object.active === true) : [];
    let active_tree = typeof active_root[0] !== 'undefined' ? active_root[0].items : [];
    let data = { 
        active_item: active_root,
        items: active_tree 
    }
    return data
}

// TODO: hack for now
function postTrigger(i){

    Data.program.place.fetch(i.id)
    Data.program.active_layer = 0
    lenis.scrollTo(0)
}



var ProgramNavigation = {
    oninit: function(){
        Data.program.fetch()
    },
    view: function(vnode) {
        let active_region = Data.program.items ? getTreeData(Data.program.items) : null
        let active_location = active_region ? getTreeData(active_region.items) : null
        return m('.layers', {
            class: Data.program.active_layer > 0 ? 'active_layer_'+Data.program.active_layer : '',
        },[
            m('.layer.l1', {}, m(ProgramNavigationTree, {items: Data.program.items, layer: 1})),
            active_region && active_region.items.length > 0 && m('.layer.l2', {}, m(ProgramNavigationTree, {items: active_region.items, layer: 2})),
            active_location && active_location.items.length > 0 && m('.layer.l3', {}, m(ProgramNavigationTree, {items: active_location.items, layer: 3})),
            m('.triggers', {},[
                m('button.btn.circ.material-symbols-outlined',{
                    class: Data.program.active_layer == 1 ? 'active' : '',
                    onclick: function(e) {
                        Data.program.active_layer = Data.program.active_layer != 1 ? 1 : 0;
                        Data.menu.active = false
                    }
                },'public'),
                active_region && active_region.items.length > 1 && m('button.btn.circ.material-symbols-outlined',{
                    class: Data.program.active_layer == 2 ? 'active' : '',
                    onclick: function(e) {
                        Data.program.active_layer = Data.program.active_layer != 2 ? 2 : 0;
                        Data.menu.active = false
                    }
                },'holiday_village'),
                active_location && active_location.items.length > 0 && m('button.btn.circ.material-symbols-outlined',{
                    class: Data.program.active_layer == 3 ? 'active' : '',
                    onclick: function(e) {
                        Data.program.active_layer = Data.program.active_layer != 3 ? 3 : 0;
                        Data.menu.active = false
                    }
                },'home')
           ])
        ])
    }
}
var ProgramNavigationTree = {
    view: function(vnode) {
        return m('ul', {},[
            vnode.attrs.items && vnode.attrs.items.map(function(i, index) {
                return m('li', {
                    class : 'item_' + i.id
                }, m('button.btn.menu_item', {
                        class: i.active ? 'active' : '',
                        id: 'id_'+i.id,
                        onclick: function(e){
                            if(vnode.attrs.layer == 3){
                                Data.program.active_layer = 3;
                                // TODO open the page right away
                                postTrigger(i)
                                m(Content, {item: i})
                            }
                            else if(vnode.attrs.layer == 1 && i.items.length == 1){
                                i.items[0].active = true
                                // when the whole tree is single to single structure
                                if(i.items[0].items.length == 1){
                                    i.items[0].items[0].active = true
                                    // TODO open the page right away
                                    postTrigger(i.items[0].items[0])
                                    m(Content, {item: i})
                                }else{
                                    Data.program.active_layer = 3;
                                }
                                reactivateTree(vnode.attrs.items, i)
                                reactivateTree(vnode.attrs.items[0].items, vnode.attrs.items[0])
                            }
                            else{
                                reactivateTree(vnode.attrs.items, i)
                                Data.program.active_layer = vnode.attrs.layer + 1;
                            }
                        }
                    },
                    i.title
                ))
            })
        ])
    }
}

var Navigation = {
    oninit: function(){
        Data.menu.fetch()
    },
    view: function(vnode) {
        return m('.menu_dock', {},[
            m('.layer',{
                class: Data.menu.active ? 'active' : ''
            }, [
                m('#logo'),
                m('ul', {},  
                    Data.menu.items && Data.menu.items.map(function(item, index) {
                        return m('li',{
                            class: 'item_' + item.id
                        }, [
                            m('a.btn.menu_item', {
                                href : '#',
                                id: 'id_' + item.id
                            }, item.title)
                        ])
                    })
                )
            ]),
            m('.triggers', {},[
                m('button.btn.circ.material-symbols-outlined', {
                    onclick: function(e) {
                        Data.menu.active = !Data.menu.active
                        Data.program.active_layer = 0
                    },
                    class: Data.menu.active ? 'active' : ''
                },'menu'),
           ])
        ])
    }
}
var PlaceInfo = {
    view: function(vnode) {
        if(Data.program.items.length > 0){
            let root = Data.program.items.filter((object) => object.active === true)
            let block = root[0].items && root[0].items.filter((object) => object.active === true)
            let info = block[0] && root[0].title + (root[0].title != block[0].title ? ', ' + block[0].title : '')
            return m('.info.blend', info)
        }
    }
}
var DummyContent = {
    view: function(vnode) {
        return m.trust(`<p class="perex blend">
                Milí sousedé, <br>
                hoďme se do gala, oživme Strossmayerovo náměstí a pojďme se zamyslet nad tím, jak můžeme hodit do gala i naše okolí.
            </p>
            <div class="list items local_program blend">
                <div class="item">
                    <div class="time">12:00–13:00</div>
                    <h3>
                        Příprava akce
                    </h3>
                </div>
                <div class="item">
                    <div class="time">13:00–17:00</div>
                    <h3>
                     Hoďte se na ulici do gala<button class="btn expand material-symbols-outlined light">expand_more</button>
                 </h3>
             </div>
             <div class="item">
                <div class="time">13:00–17:30</div>
                <h3>
                    Hoďme svoji duši do gala. <button class="btn expand material-symbols-outlined light">expand_more</button>
                </h3>
            </div>
            <div class="item">
                <div class="time">17:00–23:00</div>
                    <h3>
                     Klimentova tvořivá dílnička Katolické mateřské školy sv. Klimenta!
                 </h3>
             </div>
            <div class="item">
                <div class="time">12:00–13:00</div>
                <h3>
                    Příprava akce
                </h3>
            </div>
            <div class="item">
                <div class="time">13:00–17:00</div>
                <h3>
                 Hoďte se na ulici do gala<button class="btn expand material-symbols-outlined light">expand_more</button>
             </h3>
             </div>
             <div class="item">
                <div class="time">13:00–17:30</div>
                <h3>
                    Hoďme svoji duši do gala. <button class="btn expand material-symbols-outlined light">expand_more</button>
                </h3>
            </div>
            <div class="item">
                <div class="time">17:00–23:00</div>
                <h3>
                     Klimentova tvořivá dílnička Katolické mateřské školy sv. Klimenta!
                </h3>
            </div>
            <div id="map" class="blend">
                <img src="/map.png" alt="map" width="500">
            </div>
    `)
    }
}
var Content = {
    view: function(vnode) {
        if(!Data.program.place.item){
            return m('.lead', [
                m('img', {
                    src : '/graphic.svg',
                    onclick: function(){
                        Data.program.active_layer = 1
                    }
                }),
                m('button.btn.action.big',{
                    onclick: function(){
                        Data.program.active_layer = 1
                    }
                }, 'najdi si to svoje')
            ])
        }else{
            console.log(Data.program.place.item)
            return m('.location_data', [
                m('h1.heading.blend', Data.program.place.item.title),
                m(PlaceInfo),
                m(DummyContent)
            ])
        }
    }
}

if(document.getElementById('program_navigation') !== null){
    m.mount(document.getElementById('program_navigation'), ProgramNavigation);
}
if(document.getElementById('navigation') !== null){
    m.mount(document.getElementById('navigation'), Navigation);
}
if(document.getElementById('loading') !== null){
    m.mount(document.getElementById("loading"), Loading);
}
if(document.getElementById('content') !== null){
    m.mount(document.getElementById("content"), Content);
}
// if(document.getElementById('place_info') !== null){
//     m.mount(document.getElementById("place_info"), PlaceInfo);
// }
// if(document.getElementById('dummy_content') !== null){
//     m.mount(document.getElementById('dummy_content'), DummyContent);
// }